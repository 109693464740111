import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Col,Row, Card } from 'reactstrap'
import {css} from 'emotion'
import { Link } from "gatsby"

class BlogIndex extends React.Component {

    render() {
        const { data } = this.props
        const posts = data.allMarkdownRemark.edges
        return (
            <Layout headerType='blog' headerText={'Design Technology Blog'}>
            <Container className={css`margin-top: 20px;`}>
              <Row>
              <Col className={'col-12 col-lg-6 offset-lg-3 text-center'}>
                <SEO title="Design Technology Blog" />
                  {posts.map(({ node }) => {
                    const title = node.frontmatter.title || node.fields.slug
                      return (
                        <Card key={node.fields.slug} className={css`
                            margin-bottom: 50px;
                            padding: 20px;
                            -webkit-box-shadow: 3px 6px 30px -20px rgba(0,0,0,0.36);
                            -moz-box-shadow: 3px 6px 30px -20px rgba(0,0,0,0.36);
                            box-shadow: 3px 6px 30px -20px rgba(0,0,0,0.36);
                          `}>
                            <Link className={css`
                              color: #000; 
                              font-family: Nunito;
                              font-weight: 600; 
                              font-size: 28px;
                              a:hover {
                              text-decoration: none;
                              }
                            `} to={`blog${node.fields.slug}`}>
                              <img src={node.frontmatter.featuredImage.childImageSharp.sizes.src} alt={node.frontmatter.imageCaption} width='100%' /> 
                              <h3 className={css`margin-bottom: 0px;`}></h3>
                              {title}
                            </Link>
                            <div className={css`font-size: 14px; margin-bottom: 20px;`}>{node.frontmatter.date}</div>
                            <p className='text-left'
                              dangerouslySetInnerHTML={{
                                __html: node.excerpt,
                              }}
                            />
                            <Link className='text-left' to={`blog${node.fields.slug}`}>Read More →</Link>
                        </Card>
                      )
                })}
              </Col>
              </Row>
            </Container>
          </Layout>
        )
    }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 160)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            featuredImage {
              childImageSharp{
                  sizes(maxWidth: 630) {
                      ...GatsbyImageSharpSizes
                  }
              }
            }
            imageCaption
          }
        }
      }
    }
  }
`

export default BlogIndex
